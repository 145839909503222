'use client'

import { trpc } from '@/app/trpc/webClient'
import { useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'

export function useClients() {
  const pathname = usePathname()
  const isAuthenticated = useSession().status === 'authenticated'
  const { data: clients, isLoading: isLoadingClients } = trpc.listAllRealClients.useQuery(undefined, {
    queryKeyHashFn: () => pathname, // refresh if user changes page, e.g. from /admin/client to /investigation
    enabled: isAuthenticated,
  })

  return { clients, isLoadingClients }
}
