import { z } from 'zod'

export type Option<Value extends string = string, Data = any> = {
  value: Value
  label: string
  /**
   * Element to be displayed at the start of the dropdown option.
   */
  startAdornment?: React.ReactNode

  /**
   * Element to be displayed at the end of the dropdown option.
   */
  endAdornment?: React.ReactNode
} & (Data extends null | undefined ? { data?: Data } : { data: Data })

export function OptionSchema<Value extends z.ZodType = z.ZodString, Data extends z.ZodType = z.ZodOptional<z.ZodAny>>(
  valueType: Value = z.string() as unknown as Value,
  dataType: Data = z.any().optional() as unknown as Data,
) {
  return z.object({
    value: valueType,
    label: z.string(),
    data: dataType,
    startAdornment: z.any().optional(),
    endAdornment: z.any().optional(),
  })
}

export const DateRangeSchema = z.object({
  from: z.union([z.date(), z.string()]).nullish(),
  to: z.union([z.date(), z.string()]).nullish(),
})

export function idAndNameToDataOption<Data extends { id: string; name?: string | null }>(obj: Data) {
  return { value: obj.id, label: obj.name ?? '', data: obj }
}
