import { z } from 'zod';
import { ClientTypeSchema } from '../inputTypeSchemas/ClientTypeSchema'

/////////////////////////////////////////
// CLIENT SCHEMA
/////////////////////////////////////////

export const ClientSchema = z.object({
  type: ClientTypeSchema.nullish(),
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date().nullish(),
  companyName: z.string().nullish(),
  sftpServerUsername: z.string().nullish(),
  sftpHomeDir: z.string().nullish(),
  sftpClientUsername: z.string().nullish(),
  sftpHost: z.string().nullish(),
  sftpPort: z.number().int().nullish(),
  pgpPublicKey: z.string().nullish(),
  isResultSubmissionEnabled: z.boolean().nullish(),
  isEnrollmentFileIngestEnabled: z.boolean().nullish(),
  isClaimFileIngestEnabled: z.boolean().nullish(),
  isControlFileIngestEnabled: z.boolean().nullish(),
  isCardTxnFileIngestEnabled: z.boolean().nullish(),
  isReEnrollmentFileIngestEnabled: z.boolean().nullish(),
})

export type Client = z.infer<typeof ClientSchema>

export default ClientSchema;
