import { daysToMs } from '@core/util/time'
import { addDays, format, set } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { toZonedTime } from 'date-fns-tz'
import { z } from 'zod'

export const DateRangeSchema = z.object({
  from: z.union([z.date(), z.string()]).nullish(),
  to: z.union([z.date(), z.string()]).nullish(),
})

export type DateRange = z.infer<typeof DateRangeSchema>

// convert a Date to YYYY-MM-DD format
export function dateToYMD(date: Date | string | null): string {
  if (!date) {
    return ''
  }
  return format(new Date(date), 'yyyy-MM-dd')
}

// convert a Date to YYYY-MM-DD HH:mm:ss format
export function dateToYMDAndTime(date: Date): string {
  return format(new Date(date), 'yyyy-MM-dd | HH:mm:ss')
}

export function isExpired(expiresAtMs: number, buffer = 0) {
  return expiresAtMs - buffer < Date.now()
}

export const ONE_HOUR_IN_MS = 60 * 60 * 1000

/**
 * Get a local date from a UTC date so that year, month, and day are the same.
 */
export function utcToLocalDate(date: string | Date) {
  let year: number, month: number, day: number

  if (typeof date === 'string') {
    // Handle the case when input is a string
    ;[year, month, day] = date.split('T')[0].split('-').map(Number)
  } else if (date instanceof Date) {
    // Handle the case when input is a Date object
    year = date.getUTCFullYear()
    month = date.getUTCMonth() + 1 // Months are zero-indexed in JS
    day = date.getUTCDate()
  } else {
    throw new Error('Invalid input type. Expected a string or Date object.')
  }

  // Create a local date without timezone shift
  return new Date(year, month - 1, day)
}

/**
 * Get a YMD date `days` days from now.
 * @param days - The number of days from now to calculate the date.
 * @returns The date `days` days from now in YMD format.
 */
export const daysFromNowToYMD = (days: number) => {
  const date = new Date(Date.now() + daysToMs(days))
  return dateToYMD(date)
}

/**
 * Get the next occurrence of the hour in a given timezone.
 */
export function getNextHourOccurrenceInTimeZone(hours: number, timeZone: string): Date {
  const now = new Date()
  const timeZoneNow = toZonedTime(now, timeZone)

  let nextHourOccurrence = set(timeZoneNow, {
    hours,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })

  if (timeZoneNow >= nextHourOccurrence) {
    nextHourOccurrence = addDays(nextHourOccurrence, 1)
  }

  return fromZonedTime(nextHourOccurrence, timeZone)
}

export function ymdSerializeDateRange(dateRange?: DateRange | null) {
  if (!dateRange?.from && !dateRange?.to) {
    return undefined
  }

  return {
    from: dateRange.from ? dateToYMD(dateRange.from) : undefined,
    to: dateRange.to ? dateToYMD(dateRange.to) : undefined,
  }
}
